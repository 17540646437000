import { Component, OnInit, Output, EventEmitter, Input, HostListener, ViewChild, AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { BroadcastService } from '../services/broadcast.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-sidepanel',
  templateUrl: './sidepanel.component.html',
  styleUrls: ['./sidepanel.component.scss'],
  providers: [DatePipe]
})

export class SidepanelComponent {
  constructor() { }
}

