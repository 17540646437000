import { Injectable } from '@angular/core';
import { BroadcastService } from "../services/broadcast.service";

@Injectable({
  providedIn: 'root'
})
export class ControllerService {
  datauxview: any;
  dfx: any;
  distance: any = 1800;

  constructor(private broadcastService: BroadcastService) { }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastService.getInstance().next(data);
  }

  /* * * *
  * init dataux
  * * * */
  init(_datauxview) {
    this.datauxview = _datauxview;
    this.dfx = _datauxview.getDatascape();
  }

  /* * * *
  * move the object position
  * * * */
  move(element, posObj) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "pos": posObj } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * rotate the object
  * * * */
  rotate(element, ang) {
    if (element) {
      let props = this.dfx.props(element);
      var data = { "time": "1", "op": "moveTo", "name": props.id, "duration": 1.720, "place": { "rot": ang } }
      this.datauxview.modifyElement(data);
    }
  }

  /* * * *
  * check if object is visible or not
  * * * */
  isObjVisible(c) {
    let comp = this.datauxview.getElementId(c);
    if (comp) {
      let props = this.dfx.props(comp);
      let vis = props.profile.visible;
      return vis;
    } else {
      return null;
    }
  }

  /* * * * *
  * show particluar element
  * * * * * */
  showElement(name) {
    let element = this.datauxview.getElementId(name);
    if (element) {
      var _obj = { "profile": 'regular' };
      this.dfx.modify(element, _obj);
    }
  }

  /* * * * *
  * hide particluar element
  * * * * * */
  hideElement(name) {
    let show = this.isObjVisible(name);
    if (show) {
      let element = this.datauxview.getElementId(name);
      var _obj = { "profile": 'invisible' };
      this.dfx.modify(element, _obj);
    }
  }
  /* * * * *
  * modify element
  * * * * * */
  modifyElement(name, prop, val, obj = null) {
    let exist = this.datauxview.getElementId(name);;
    if (exist) {
      var _obj = {};
      if (obj) {
        _obj = obj
      } else {
        _obj[prop] = val;
      }

      this.dfx.modify(exist, _obj);
    }
  }

}
