import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpModule } from '@angular/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from "@angular/forms";
import { DatauxviewModule } from 'datauxview';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { from } from 'rxjs';

//components
import { NavyappComponent } from './navyapp/navyapp.component'
import { HeaderComponent } from './header/header.component';
import { ControltoolsComponent } from './controltools/controltools.component';
import { SidepanelComponent } from './sidepanel/sidepanel.component';
import { TagComponent } from './tag/tag.component';

//providers
import { ConfigService } from './services/config.service';
import { ControllerService } from './services/controller.service';
import { BroadcastService } from './services/broadcast.service';
import { TagService } from './services/tag.service';
import { customInterceptor } from './services/http.interceptor';

// mat components
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/', '-lang.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    ControltoolsComponent,
    SidepanelComponent,
    NavyappComponent,
    TagComponent
  ],
  imports: [
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserModule,
    FormsModule,
    HttpModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    DatauxviewModule,
    ReactiveFormsModule,

    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatProgressSpinnerModule
  ],
  entryComponents: [
  ],
  exports: [
    BrowserModule,
    HttpModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    ConfigService,
    ControllerService,
    BroadcastService,
    TagService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: customInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
