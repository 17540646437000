import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ConfigService {
  accessToken: any;
  refershToken: any;
  private config: any;
  private _jsonURL = '../assets/project.config.json';
  project_config: any;
  baseURL = 'https://nodesdms.azurewebsites.net/jetty/'

  constructor(private http: HttpClient) {
  }

  getJSON(url = null): Observable<any> {
    return this.http.get(url || this._jsonURL);
  }

  getProjectConfig() {
    let self = this;
    return new Promise((resolve, reject) => {
      if (self.config) {
        resolve(self.config)
      } else {
        self.getJSON().subscribe(data => {
          self.config = data[data["config"]];
          this.project_config = data[data["config"]];
          console.log("data config", this.project_config)
          resolve(self.config)
        },
          error => reject(error)
        );
      }
    })
  }

  loadFile(url) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getJSON(url).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * get api call
  * * */
  getJsonData(url) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.getJSON(url).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * method for get Json data from server
  * * */
  pullJsonData(url, data) {
    let self = this;
    return new Promise((resolve, reject) => {
      self.postData(url, data).subscribe(data => {
        resolve(data)
      },
        error => reject(error)
      );
    })
  }

  /* * *
  * post api call
  * * */
  getData(url): Observable<any> {
    return this.http.get(url);
  }
  postData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    const body = JSON.stringify(data);
    return this.http.post(url, body, { 'headers': headers })
  }
  putData(url, data): Observable<any> {
    const headers = { 'content-type': 'application/json' }
    return this.http.put(url, data, { 'headers': headers })
  }
  deleteData(url: string): Observable<any> {
    return this.http.delete(url)
  }


}
