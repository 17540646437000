import * as Rx from 'rxjs';

export class BroadcastService {

    private instance: Rx.Subject<any>;

    createInstance(): Rx.Subject<any> {
        const instance = new Rx.Subject<any>();

        return instance;
    }

    public getInstance(): Rx.Subject<any> {

        if (!this.instance) {
            this.instance = this.createInstance();
        }

        return this.instance;
    }
}
