import { Component, ViewChild, AfterViewInit, HostListener, Inject, } from '@angular/core';
import { DatePipe } from '@angular/common';

// service
import { ConfigService } from '../services/config.service';
import { BroadcastService } from "../services/broadcast.service";
import { ControllerService } from '../services/controller.service';
import { TagService } from '../services/tag.service';

declare var BABYLON;
@Component({
  selector: 'app-navyapp',
  templateUrl: './navyapp.component.html',
  styleUrls: ['./navyapp.component.scss'],
  providers: [DatePipe]
})

export class NavyappComponent implements AfterViewInit {
  @ViewChild('datauxview', { static: true }) public datauxview: any;

  dfx: any;
  projectConfig: any;
  process_txt = "Processing...";
  pcsDemos = [];
  selectedDemo = this.pcsDemos[0] || "";
  showLoading: boolean = false;
  showBlockLoading: boolean = false;
  broadcastSubs
  controlPanelState: boolean;
  allElements = [];
  cam_mode: any;

  constructor(private datePipe: DatePipe, private tagServ: TagService, private configService: ConfigService, private ctrlServ: ControllerService, private broadcastService: BroadcastService) {
    this.broadcastSubs = this.broadcastService.getInstance()
      .subscribe((data: any) => {
      })
  }

  /* * * * *
  * method for communicate event instance with data to access all components
  * * * * * */
  broadcastInfo(data: any) {
    this.broadcastService.getInstance().next(data);
  }

  /* * * * *
  * method for project config json based on load the project
  * initilaze the objects form settings.json
  * * * * * */
  ngAfterViewInit(): void {
    this.configService.loadFile('../assets/license.info').then((info) => {
      this.configService.getProjectConfig().then((project_config: any) => {
        project_config.licence = info;
        this.projectConfig = project_config;
        this.datauxview.setProjectSettins(project_config);
        this.datauxview.loadCanvas('settings', 'json', (data, settingstatus) => {
          var setting_status = settingstatus;
          if (!setting_status) {
          } else {
            this.initObjects();
          }
        });
      }, (err) => { console.log(err) });
    }, (err) => { alert("License not found!") })

    let canvas = document.getElementById('canvasElem').children[0].children[0];
    canvas.addEventListener('pointerdown', (evt) => {
    })
    canvas.addEventListener('pointerup', (evt) => {
    })
  }

  /* * * * *
  * init onjects
  * loading all the static objects from objects.json
  * * * * * */
  initObjects() {
    // load all the assets to be used information from socket
    this.datauxview.loadAssets('objects', 'json', (objectstatus) => {
      if (objectstatus) {
        // load all the object settings from settings json file
        this.datauxview.renderAssets('objects', 'json', (objectstatus) => {
          if (objectstatus) {
            console.log('objects', objectstatus);
            this.dfx = this.datauxview.getDatascape();
            this.allElements = this.datauxview.getElementsid();
            this.ctrlServ.init(this.datauxview);
            this.tagServ.init(this.datauxview);
            this.addEnv();
            this.processModel();
          }
        }, (id, pointer) => this.animate(id, pointer));
      }
    });
  }

  /* * * *
  * Zoom event to zoom in / out the venue
  * * * */
  isMouseDown = false;
  Zoom(key) {
    this.isMouseDown = true;
    this.datauxview.startZoom(key);
  }

  stopZoom() {
    this.isMouseDown = !true;
    this.datauxview.stopZoom();
  }

  @HostListener('window:message', ['$event'])
  onmessage(e) {
  }

  @HostListener('document:mouseup', ['$event'])
  handleMouseUpEvent(event: MouseEvent) {
    if (this.isMouseDown) {
      this.stopZoom();
    }
  }

  @HostListener('document:pointerdown', ['$event'])
  handleMouseDownEvent(event: MouseEvent) {
    if ((<HTMLInputElement>event.target).id == "renderCanvas") {
      const hit = this.dfx.getHitPosition();
      let mesh = hit.pickedMesh;
      if (mesh != null) {
        //console.log("pickedPoint mesh :: ", mesh.name)
        //console.log("pickedPoint :: ", hit.pickedPoint)
      }
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardUPEvent(event: KeyboardEvent) {
    if (event.key) {
      const key = event.key.toString().toLowerCase();
      if (event.shiftKey && key === 't') {
        this.controlPanelState = !this.controlPanelState;
      }
    }
  }

  /* * * * *
  * Camera Position event to move the camera to different view / angle
  * * * * * */
  onChangeCameraPosition(camView) {
    console.log("camView ", camView);
    this.datauxview.Camera(camView);
  }

  /* * * * *
  * Control Tool
  * * * * * */
  controlToolEvent(control) {
    if (control.name === 'home') {
      this.changeCamView(control.name);
    } else if (control.name === 'zoomin' || control.name === 'zoomout') {
      let zoomPosition = control.name === 'zoomin' ? ']' : '[';
      this.Zoom(zoomPosition);
    } else if (control.icon === 'dimension') {
      control.name = control.state ? '2D' : '3D';
      this.onChangeCameraMode(control.name);
    } else if (control.name === 'left') {
      this.changeCamView(control.name);
    } else if (control.name === 'right') {
      this.changeCamView(control.name);
    } else if (control.name === 'back') {
      this.changeCamView(control.name);
    } else if (control.name === 'top') {
      this.changeCamView(control.name);
    } else if (control.name === 'front') {
      this.changeCamView(control.name);
    } else if (control.name === 'v1') {
      this.changeCamView(control.name);
    } else if (control.name === 'v2') {
      this.changeCamView(control.name);
    } else if (control.name === 'v3') {
      this.changeCamView(control.name);
    } else if (control.name === 'v4') {
      this.changeCamView(control.name);
    }
  }

  camviews = {
    "home": { "target": { "x": 1100.7074, "y": 0, "z": -3156.7589 }, "distance": 4000, "yaw": 255, "pitch": 27.85 },
    "2D": { "target": { "x": 982.7074, "y": 0, "z": -3156.7589 }, "distance": 4000, "yaw": 245, "pitch": 27.85 },
    "top": { "target": { "x": 982.4944, "y": 0, "z": -3151.9262 }, "distance": 4000, "yaw": 245, "pitch": 89.9 },

    "v1": { "target": { "x": 1135.5853, "y": 0, "z": -3185.6144 }, "distance": 1300, "yaw": -35, "pitch": 22.85 },
    "v2": { "target": { "x": 1145.6547, "y": -4, "z": -3180.6081 }, "distance": 150, "yaw": 160, "pitch": 7.85 },
    "v3": { "target": { "x": 1300.9253, "y": 0, "z": -2533.2735 }, "distance": 450, "yaw": 160, "pitch": 12.85 },

    "v4": { "target": { "x": 1481.6572, "y": 10, "z": -2785.14096 }, "distance": 200, "yaw": -110, "pitch": 17.85 },

    "front": { "target": { "x": 797.7886, "y": 10, "z": -3103.5714 }, "distance": 3200, "yaw": -35, "pitch": 27.85 },
    "back": { "target": { "x": 747.7886, "y": 10, "z": -3003.5714 }, "distance": 3000, "yaw": 145, "pitch": 27.85 },
    "right": { "target": { "x": 797.7886, "y": 10, "z": -3103.5714 }, "distance": 3800, "yaw": 55, "pitch": 27.85 },
    "left": { "target": { "x": 797.7886, "y": 10, "z": -3103.5714 }, "distance": 3800, "yaw": -130, "pitch": 27.85 },
  }

  changeCamView(v, vobj = false) {
    let view = vobj ? v : this.camviews[v];
    this.moveCameraTo(null, view)
  }

  moveCameraTo(el, view = null) {
    let dfx = this.datauxview.getDatascape();
    let camera = dfx.getCamera();
    let _view = view;
    if (!view) {
      let dr = this.getElemRadius(el);
      let target = this.getElemCenter(el);
      let ds = dr / Math.tan(camera.fov / 2);
      ds = Math.max(20, ds);
      _view = { distance: ds, target }
    }
    dfx.moveCamera(_view);
  }
  getElemCenter(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let target;
    let mesh = dfx.getElementMesh(el);
    // if(this.extras.includes(id)){
    // target=this.getAverageCenter(mesh);
    // }else{
    let c = mesh.getBoundingInfo().boundingSphere.centerWorld;
    target = { x: c.x, y: c.y, z: c.z }
    // }
    return target;
  }

  getElemRadius(el) {
    let dfx = this.datauxview.getDatascape();
    let id = dfx.props(el).id;
    let mesh = dfx.getElementMesh(el);
    let c = id ? this.getMaxRadius(mesh) : mesh.getBoundingInfo().boundingSphere.radiusWorld;
    return c;
  }

  getMaxRadius(m) {
    let meshes = m.getChildMeshes();
    let l = meshes.length;
    let c = Number.MIN_VALUE
    meshes.forEach((mesh) => {
      if (mesh.isEnabled() && mesh.isVisible && mesh.material.alpha) {
        let pt = mesh.getBoundingInfo().boundingSphere.radiusWorld;
        c = Math.max(c, pt)
      }
    });
    return c;
  }


  /* * * * *
  * change cameta mode
  * * * * * */
  onChangeCameraMode(camView) {
    this.datauxview.venue_orientation = 0.35;
    this.datauxview.yaw_2d = 245;
    this.datauxview.yaw_3d = 255;
    this.datauxview.pitch_3d = 27.85;

    this.cam_mode = camView;
    if (this.cam_mode == '2D') {
      this.changeCamView('top');
    }
    this.datauxview.CameraMode(camView);
    if (this.cam_mode == '3D') {
      setTimeout(() => {
        this.changeCamView('home');
      }, 1300);
    }
  }

  selectedElement = {
    "id": "none",
    "geometry": {
      "position": { "x": 0, "y": 0, "z": 0 },
      "size": 0,
      "orientation": { "x": 0, "y": 0, "z": 0 },
    }
  };

  /* * * *
  * Get the handle to the element selected from list
  * * * */
  onGetElementProperties(key) {
    var elem = this.datauxview.getObjectProperties(key);
    this.selectedElement = elem;
    console.log("this.selectedElement :", this.selectedElement);
  }

  /* * * * *
  * Change the position / coordinates on the selected object
  * * * * * */
  onChangeObjectPosition(objProp) {
    const obj = {
      "name": this.selectedElement.id,
      "place": {
        "pos": objProp.position,
        "rot": { "x": null, "y": null, "z": null }
      }
    };
    this.datauxview.modifyElement(obj);
  }

  /* * * * *
  * Change the size on the selected object
  * * * * * */
  onChangeObjectSize(objProp) {
    const obj = {
      "name": this.selectedElement.id,
      "place": {
        "pos": { "x": null, "y": null, "z": null },
        "rot": { "x": null, "y": null, "z": null },
        "size": objProp.size
      }
    };
    this.datauxview.modifyElement(obj);
  }

  /* * * * *
  * change object Orientation
  * * * * * */
  onChangeObjectOrientation(objProp) {
    const obj = {
      "name": this.selectedElement.id,
      "place": {
        "pos": { "x": null, "y": null, "z": null },
        "rot": { "x": null, "y": objProp.orientation, "z": null },
        "size": null
      }
    };
    this.datauxview.modifyElement(obj);
  }

  /* * * * *
  * trigger Event
  * * * * * */
  ontrigger(keycode) {
    this.datauxview.triggerKey(keycode);
  }

  /* * * * *
  * Animate Event
  * * * * * */
  animate(id, pointer) {
    if (pointer == 'pickLeft') {
    }
  }

  settingPanelState(e) {
    this.controlPanelState = e;
  }
  /**
   * sky effect
   */
  sky
   addEnv() {
    this.sky = this.dfx.addSky('sky', { size: 30000, rayleigh: 1, inclination: 0.1 });
   }
   processModel(){
     let obj=this.datauxview.getElementId("stjharbour");
     this.dfx.setElementScale(obj,[1,1,-1]);
    let cam = this.dfx.getCamera();
    let scene = cam.getScene();
    scene.materials.forEach((m) => {
      if (m.metallic) {
        m.metallic = 0;
        m.roughness = 0.5;
      }
    })
   }

}

